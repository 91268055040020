import React, { useState, useEffect } from "react";
import { ArrowClockwiseIcon, PlayIcon, PauseIcon } from '../Components/Icons';
import c from './players_style.module.css';

//import sound from '../assets/BirthdayMP3.mp3';

const useAudio = (url, setTrack) => {
  
  const [playing, setPlaying] = useState(false); 
  const [audio] = useState(new Audio(url));
  const toggle = () => setPlaying(!playing);
  audio.play();
  useEffect(() => { playing ? audio.play() : audio.pause();},
    // eslint-disable-next-line 
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setTrack(2));
    return () => {
      audio.removeEventListener('ended', () => setTrack(2));
    };
    // eslint-disable-next-line 
  }, []);
  return [playing, toggle];
};

const Player = ( {setTrack} ) => { 
  const [playing, toggle] = useAudio('https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/BirthdayMP3.mp3?alt=media&token=11af582d-9c0c-42f4-9b8b-a3683493ff6d', setTrack);

  const changeTrack = () => {    
    toggle();
    setTrack(2);
  };
  const backTrack = () => { 
    toggle();
   setTrack(5); 
  };
  return (
    <>
    
       <div className={c.screen}>
         <span className={c.run_the_text} >
           Happy Birthday!!!
         </span>      
       </div>
        <div className={c.volume_box3} onClick={() => backTrack()}>
          <span className={c.turnaround}>{ArrowClockwiseIcon(35)}</span>
        </div> 

        <div onClick={toggle} className={c.volume_box}>
          {playing ? PauseIcon(35, 'red') : PlayIcon(35)}    
        </div>
        
        <div className={c.volume_box2} onClick={() => changeTrack()}>
          {ArrowClockwiseIcon(35)}
        </div>     
     
    </>
  );
};

export default Player;