
import c from './happy_style.module.css';
import b from './mob_style.module.css';
import yarik from '../assets/yarik2.jpg';
import eva from '../assets/eva2.jpg';
import eva_crd from '../assets/eva_crd.jpg';
import dasha from '../assets/dasha.jpg';
import denya from '../assets/denya2.jpg';
import vera from '../assets/Vera.jpg';
//import vera_vid from '../assets/vera_vid.mp4';
import colegues1 from '../assets/colegues1.jpeg';
//import col1_vid from '../assets/col1_vid.mp4';
import ann from '../assets/ann.jpg';
//import ann_vid from '../assets/ann_vid.mp4';
import katya from '../assets/katya.jpeg';
//import katya_vid from '../assets/katya_vid.mp4';
import lera from '../assets/lera.jpeg';
//import lera_vid from '../assets/lera_vid.mp4';
import arhipova from '../assets/arhipova.jpeg';
//import arhipova_vid from '../assets/arhipova_vid.mp4';
import mitrofanova from '../assets/mitrofanova.jpg';
//import mitrofanova_vid from '../assets/mitrofanova_vid.mp4';
import kameneva from '../assets/kameneva.jpg';
import kameneva_crd from '../assets/kameneva_crd.jpg';
import koicheva from '../assets/koicheva.jpg';
import koicheva_crd from '../assets/koicheva_crd.jpg';
import zornitsa from '../assets/zornitsa.jpg';
import ivan from '../assets/ivan.jpg';
import stefa from '../assets/stefa.jpg';
import vova from '../assets/vova.jpg';
import luda from '../assets/luda.jpg';
//import luda_vid from '../assets/luda_vid.mp4';
import glass from '../assets/cake.gif';

import VideoWindow from './VideoWindow';
import CaruselComponent from './CaruselComponent';


 const HappyBirthday = () => {


  return (
    <>
   
    <div className={c.container}>     
      <div className={c.card_container}>
       <div className={c.card}>
        <div className={c.left_card}>
          <div className={c.outside}/>      
          <div className={c.inside}>
              <img src={glass} alt='img_Cake' id={c.cake}/>
              <div className={c.vitalniy_txt}>
                З Днем Народження <br/> Кохана!
              </div>
          </div>
        </div>

        <div className={c.right_card}>                 
            <CaruselComponent />       
         {/* <img  key={index} src={cover2} alt='img_Cake' id={c.cover2}/>  */}             
        </div>
       </div>
      </div>

      <div className={c.block_container}>
        <div className={c.block}>
            <div className={`${c.frames} ${c.frame1}`}>
              <img src={dasha} alt='userPhoto'/>
              <p>
                Вітаю з днем народження, мило моя мамо! 
                Бажаю тобі, здоров’я, щастя та добра, 
                любові, радощів та гарного настрою. 
                Мамо, нехай кожен день дарує тобі впевненість, натхнення та вдачу.
              </p>
            </div>
            <div className={`${c.frames} ${c.frame2}`}>
              <img src={eva} alt='userPhoto'/>
              <div>
                 <p>
                Дорога матінка, в цей чудовий день, 
                вітаю тебе з Днем Народження. 
                Хай все буде таке ж як ти чудове, і я сподіваюсь що цей день
                і все твоє життя все буде просто відмінно!
                Бажаю тобі щастя, здоров'я, та всього найкращого. 
                Сподіваюсь що ти будеш такою доброю і красивою завжди! 
                Я тебе люблю і дякую що ти є.
              </p>
                <VideoWindow 
                    vid={false}
                    img={eva_crd}
                  />
              </div>
             
                 
            </div>
            <div className={`${c.frames} ${c.frame3}`}>
              <img src={yarik} alt='userPhoto'/>
              <p>
                Дорога мамуся вітаю тебе з днем народження! Ти найкраща мама у світі. 
                Я тебе дуже сильно люблю. Бажаю тобі щястя,
                здоров'я та щоб все сталося так, як ти мрієш.
              </p>
            </div>
           
            <div className={`${c.frames} ${c.frame4}`}>
              <img src={denya} alt='userPhoto'/>
              <div className={c.btn_container}>                 
                  <div className={c.txt_container}>                    
                    Дружину свою я люблю безмірно!<br/>
                    І сьогодні в день її народження 
                    я хочу подарувати їй всі зірки з неба, все тепло Сонця і прохолоду Місяця,
                    нехай твої мрії не залишаються ними, а втілюються в життя якомога швидше! 
                    Посміхайся, смійся і будь щасливою, а я тобі в цьому допоможу! <br/>
                    <strong>З Днем Народження, Кохана!</strong>
                    {/* Улюблена моя, з Днем народження! Я подарую тобі цілу галактику любові
                     і зроблю так, щоб вона перебувала в нескінченній всесвіту щастя, 
                     складалася з мільярдів яскравих зірок приємних життєвих моментів, 
                     а метеори успіхів, благополуччя і радості ніколи не вилітали за її межі. */}
                  </div>                   
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame5}`}>
              <img src={vera} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/vera_vid.mp4?alt=media&token=796ecbf9-eedb-4aab-969f-28f69e3ac122'}
                 />
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame6}`}>
              <img src={lera} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/lera_vid.mp4?alt=media&token=46ca77c3-b203-454c-a8ee-4534c2b7d408'}
                 />
              </div>             
            </div>          

            <div className={`${c.frames} ${c.frame7}`}>
              <img src={ann} alt='userPhoto'/>
              <div className={c.btn_container}>                 
                  <p>                    
                    Вітаю!
                  </p>  
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/ann_vid.mp4?alt=media&token=5b0ee4ea-e5f6-4882-b479-835bf4c2a329'}
                 />
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame8}`}>
              <img src={katya} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/katya_vid.mp4?alt=media&token=11553bb1-7d3b-41d7-959a-e7a160609ca0'}
                 />
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame9}`}>
              <img src={arhipova} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/arhipova_vid.mp4?alt=media&token=8210a47b-dc75-4882-ae10-84081f115539'}
                 />
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame10}`}>
              <img src={kameneva} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <div className={c.btn_inline_container}>
                   <VideoWindow 
                   vid={false}
                   img={kameneva_crd}
                 />
                 <VideoWindow 
                   vid={false}
                   img={false}
                   sl={true}
                 />
                 </div>
                
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame11}`}>
              <img src={koicheva} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={false}
                   img={koicheva_crd}
                 />
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame12}`}>
              <img src={colegues1} alt='userPhoto'/>
              <div className={c.btn_container}>                 
                  <div className={c.txt_container}>                    
                    Найкращому у світі директору
                    Шлемо наші обнімашки!
                    Промінчики добра, світла і тепла,
                    Перед якими не встоять ніякі негаразди і проблеми!
                    Будуть тільки примножуватись радість, щастя і безмежна любов до всіх навколо!
                  </div>  
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/col1_vid.mp4?alt=media&token=f3ed8d3d-f763-422f-a7f8-73cf2463b05d'}
                 />
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame13}`}>
              <img src={mitrofanova} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/mitrofanova_vid.mp4?alt=media&token=e477bc3b-2571-4ebb-8e0a-97d08b660103'}
                 />
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame14}`}>
              <img src={luda} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/luda_vid.mp4?alt=media&token=406fa985-4036-476e-88ff-17c28acfcb6a'}
                 />
              </div>  
            </div>

            <div className={`${c.frames} ${c.frame15}`}>
              <img src={zornitsa} alt='userPhoto'/>
              <div className={c.btn_container}>                 
                  <div className={c.txt_container}>    
                      Бажаю міцного здоров'я, удачі, благополуччя, добра, радості,
                       кохання, щастя, гарного настрою, посмішок, яскравих вражень. 
                       Нехай тепло і затишок завжди наповнюють твій будинок, нехай 
                       сонячне світло зігріває в будь-яку погоду, а бажання виконуються 
                       за однієї думки про них. Вітаю з днем ​​народження!                    
                  </div>                 
              </div>             
            </div>

            <div className={`${c.frames} ${c.frame16}`}>             
                 <img src={ivan} alt='userPhoto'/>                             
                  <div className={` ${c.txt_container}`}>    
                    Пусть будет мир в твоей жизни, счастье, радость, 
                    добро в сердце, любовь и тепло в твоей душе, 
                    гармония и взаимопонимание в твоей семье!                  
                  </div> 
            </div>

            <div className={`${c.frames} ${c.frame17}`}>             
                 <img src={stefa} alt='userPhoto'/>                             
                  <div className={` ${c.txt_container}`}>    
                   Ира, поздравляю тебя с Днём Рождения!🥳🥳<br/>
                   Желаю, чтобы ты всегда была такой же жизнерадостной, 
                   чтоб осуществлялись все твои мечты, желаю чтобы  реализовались 
                   все твои задуманные планы. 🎈Желаю тебе всегда оставаться такой красивой,
                   быть любимой и любить. Пусть рядом всегда  будут только любящие и верные люди. 
                   И чтоб ты была довольна всем, что делаешь и чем занимаешься по жизни🎊🎉❤️❤️ 
                   Happy Birthday 🎂🍰                
                  </div> 
            </div>


            <div className={`${c.frames} ${c.frame18}`}>
              <img src={vova} alt='userPhoto'/>
              <p>
               Ира, С днем рождения! Пусть в твоем доме всегда царят покой, 
               уют и гармония. Желаю быть счастливой, радоваться жизни, 
               всегда иметь верных, ненадежных друзей. Оставайся всегда такой же молодой, 
               светлой, доброй, веселой и улыбчивой!
              </p>
            </div>

            

            <div className={`${c.frames}`}>                         
              <div className={c.btn_container}/> 
            </div>

        </div>
       
      </div>
     
   </div>

{/*/////////////////////////////////////////////////// */}

   <div className={b.container}> 
      <div className={b.card_container}>
       <div className={b.card}>
        <div className={b.left_card}>
          <div className={b.outside}/>      
          <div className={b.inside}>
              <img src={glass} alt='img_Cake' id={b.cake}/>
              <div className={b.vitalniy_txt}>
                З Днем Народження <br/> Кохана!
              </div>
          </div>
        </div>

        <div className={b.right_card}>
           <CaruselComponent />           
        </div>
       </div>
      </div>

      <div className={b.block_container}>
        <div className={b.block}>
            <div className={`${b.frames} ${b.frame1}`}>
              <img src={dasha} alt='userPhoto'/>
              <p>
                Вітаю з днем народження, мило моя мамо! 
                Бажаю тобі, здоров’я, щастя та добра, 
                любові, радощів та гарного настрою. 
                Мамо, нехай кожен день дарує тобі впевненість, натхнення та вдачу.
              </p>
            </div>
            <div className={`${b.frames} ${b.frame2}`}>
              <img src={eva} alt='userPhoto'/>
              <div>
                 <p>
                Дорога матінка, в цей чудовий день, 
                вітаю тебе з Днем Народження. 
                Хай все буде таке ж як ти чудове, і я сподіваюсь що цей день
                і все твоє життя все буде просто відмінно!
                Бажаю тобі щастя, здоров'я, та всього найкращого. 
                Сподіваюсь що ти будеш такою доброю і красивою завжди! 
                Я тебе люблю і дякую що ти є.
              </p>
                <VideoWindow 
                    vid={false}
                    img={eva_crd}
                  />
              </div>
                
            </div>
            <div className={`${b.frames} ${b.frame3}`}>
              <img src={yarik} alt='userPhoto'/>
              <p>
                Дорога мамуся вітаю тебе з днем народження! Ти найкраща мама у світі. 
                Я тебе дуже сильно люблю. Бажаю тобі щястя,
                здоров'я та щоб все сталося так, як ти мрієш.
              </p>
            </div>
            <div className={`${b.frames} ${b.frame4}`}>
              <img src={denya} alt='userPhoto'/>
              <div className={b.btn_container}>                 
                  <div className={b.txt_container}>  
                   <p>                
                      Дружину свою я люблю безмірно!<br/>
                      І сьогодні в день її народження 
                      я хочу подарувати їй всі зірки з неба, все тепло Сонця і прохолоду Місяця,
                      нехай твої мрії не залишаються ними, а втілюються в життя якомога швидше! 
                      Посміхайся, смійся і будь щасливою, а я тобі в цьому допоможу! <br/>
                      <strong>З Днем Народження, Кохана!</strong> 
                    </p>     
                  </div>                   
              </div>    
            </div>

            <div className={`${b.frames} ${b.frame5}`}>
              <img src={vera} alt='userPhoto'/>
              <div className={c.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/vera_vid.mp4?alt=media&token=796ecbf9-eedb-4aab-969f-28f69e3ac122'}
                 />
              </div>             
            </div>

            <div className={`${b.frames} ${c.frame6}`}>
              <img src={lera} alt='userPhoto'/>
              <div className={b.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/lera_vid.mp4?alt=media&token=46ca77c3-b203-454c-a8ee-4534c2b7d408'}
                 />
              </div>             
            </div>          

            <div className={`${b.frames} ${b.frame7}`}>
              <img src={ann} alt='userPhoto'/>
              <div className={b.btn_container}>                 
                  <p>                    
                    Вітаю!
                  </p>  
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/ann_vid.mp4?alt=media&token=5b0ee4ea-e5f6-4882-b479-835bf4c2a329'}
                 />
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame8}`}>
              <img src={katya} alt='userPhoto'/>
              <div className={b.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/katya_vid.mp4?alt=media&token=11553bb1-7d3b-41d7-959a-e7a160609ca0'}
                 />
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame9}`}>
              <img src={arhipova} alt='userPhoto'/>
              <div className={b.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/arhipova_vid.mp4?alt=media&token=8210a47b-dc75-4882-ae10-84081f115539'}
                 />
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame10}`}>
              <img src={kameneva} alt='userPhoto'/>
              <div className={b.btn_container}>
                 <p>Вітаю!</p>
                 <div className={b.btn_inline_container}>
                   <VideoWindow 
                   vid={false}
                   img={kameneva_crd}
                 />
                 <VideoWindow 
                   vid={false}
                   img={false}
                   sl={true}
                 />
                 </div>
                
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame11}`}>
              <img src={koicheva} alt='userPhoto'/>
              <div className={b.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={false}
                   img={koicheva_crd}
                 />
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame12}`}>
              <img src={colegues1} alt='userPhoto'/>
              <div className={b.btn_container}>                 
                  <div className={b.txt_container}>                    
                    Найкращому у світі директору
                    Шлемо наші обнімашки!
                    Промінчики добра, світла і тепла,
                    Перед якими не встоять ніякі негаразди і проблеми!
                    Будуть тільки примножуватись радість, щастя і безмежна любов до всіх навколо!
                  </div>  
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/col1_vid.mp4?alt=media&token=f3ed8d3d-f763-422f-a7f8-73cf2463b05d'}
                 />
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame13}`}>
              <img src={mitrofanova} alt='userPhoto'/>
              <div className={b.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/mitrofanova_vid.mp4?alt=media&token=e477bc3b-2571-4ebb-8e0a-97d08b660103'}
                 />
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame14}`}>
              <img src={luda} alt='userPhoto'/>
              <div className={b.btn_container}>
                 <p>Вітаю!</p>
                 <VideoWindow 
                   vid={'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/luda_vid.mp4?alt=media&token=406fa985-4036-476e-88ff-17c28acfcb6a'}
                 />
              </div>  
            </div>

            <div className={`${b.frames} ${b.frame15}`}>
              <img src={zornitsa} alt='userPhoto'/>
              <div className={b.btn_container}>                 
                  <div className={b.txt_container}>    
                      Бажаю міцного здоров'я, удачі, благополуччя, добра, радості,
                       кохання, щастя, гарного настрою, посмішок, яскравих вражень. 
                       Нехай тепло і затишок завжди наповнюють твій будинок, нехай 
                       сонячне світло зігріває в будь-яку погоду, а бажання виконуються 
                       за однієї думки про них. Вітаю з днем ​​народження!                    
                  </div>                 
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame16}`}>
                 <img src={ivan} alt='userPhoto'/>
              <div className={b.btn_container}>                 
                  <div className={` ${b.txt_container}`}>    
                    Пусть будет мир в твоей жизни, счастье, радость, 
                    добро в сердце, любовь и тепло в твоей душе, 
                    гармония и взаимопонимание в твоей семье!                  
                  </div>                 
              </div>             
            </div>

            <div className={`${b.frames} ${b.frame17}`}>             
                 <img src={stefa} alt='userPhoto'/>                             
                  <div className={` ${b.txt_container}`}>    
                   Ира, поздравляю тебя с Днём Рождения!🥳🥳<br/>
                   Желаю, чтобы ты всегда была такой же жизнерадостной, 
                   чтоб осуществлялись все твои мечты, желаю чтобы  реализовались 
                   все твои задуманные планы. 🎈Желаю тебе всегда оставаться такой красивой,
                   быть любимой и любить. Пусть рядом всегда  будут только любящие и верные люди. 
                   И чтоб ты была довольна всем, что делаешь и чем занимаешься по жизни🎊🎉❤️❤️ 
                   Happy Birthday 🎂🍰                
                  </div> 
            </div>

            <div className={`${b.frames} ${b.frame18}`}>
              <img src={vova} alt='userPhoto'/>
              <p>
               Ира, С днем рождения! Пусть в твоем доме всегда царят покой, 
               уют и гармония. Желаю быть счастливой, радоваться жизни, 
               всегда иметь верных, ненадежных друзей. Оставайся всегда такой же молодой, 
               светлой, доброй, веселой и улыбчивой!
              </p>
            </div>



            <div className={`${b.frames}`}>                         
              <div className={b.btn_container}/> 
            </div>

        </div>
       </div>
      </div>
    </>
  )
}

export default HappyBirthday
