import React, { useState }  from 'react';
import c from './video_style.module.css';
import { Container, Button, Modal, Carousel, CarouselItem, Image } from 'react-bootstrap';

// import s1 from '../assets/slides/slayd1.jpg';
// import s2 from '../assets/slides/slayd2.jpg';
// import s3 from '../assets/slides/slayd3.jpg';
// import s4 from '../assets/slides/slayd4.jpg';
// import s5 from '../assets/slides/slayd5.jpg';
// import s6 from '../assets/slides/slayd6.jpg';
// import s7 from '../assets/slides/slayd7.jpg';

const VideoWindow = ( {vid, img, sl} ) => {

    const [showModalForm, setShowModalForm] = useState(false);

    //const images = [ s1, s2, s3, s4, s5, s6, s7 ];
    const images = [
      'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/slayd1.jpg?alt=media&token=57c3e1c9-a085-4fcd-9552-ddf09d5f4ef2',
      'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/slayd2.jpg?alt=media&token=6cead3a4-3c9e-4f3d-ba34-50998018da48',
      'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/slayd3.jpg?alt=media&token=88bd1e9c-625a-4809-83bb-cc51e47bb908',
      'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/slayd4.jpg?alt=media&token=4b2eaf6e-f46e-496e-9578-a36cde8652f2',
      'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/slayd5.jpg?alt=media&token=c1c11ca6-66d2-4b57-b102-1ef32d30a2ff',
      'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/slayd6.jpg?alt=media&token=f1162b7a-9437-41ce-a82c-825aff0c6048',
      'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/slayd7.jpg?alt=media&token=2a59c929-13c0-4fde-a399-4b8bd6180da2'
    ];


  return (
    <div>
         <Button variant='outline-light' onClick={() => setShowModalForm(true)} className={c.btn}>
            {vid ? ('Video') : (sl ? ('Slides') : ('Card'))}
         </Button> 
              
        
        <Modal          
          show={showModalForm}
          onHide={() => setShowModalForm(false)} 
          size="lg"
          className={c.modal}
          >
         
           <Modal.Body className={c.modal}>
             <Container className={c.modal__form}>
              {vid ? (
                <video loading='lazy' controls="controls" className={c.vid_window}>                    
                    <source src={vid} type="video/mp4"/>
                </video>  
              ) : (
                <div>
                   {sl ? (
                    <Carousel  className={c.carusel}>
                    {images.map( (image, index) =>                     
                      <CarouselItem key={index} >                                               
                          <Image key={index} src={image} id={c.cover2} className={``} />
                      </CarouselItem>)            
                    }
                   </Carousel>    
                   ) : (                    
                     <Image  src={img}  className={c.crd_img} />     
                   )}
                </div> 
              )}
                     
             </Container>
              
           </Modal.Body>
           <Modal.Footer  className={c.modal__footer}>
              <Button onClick={() => setShowModalForm(false)} variant="info" className={c.btn}>
                Сховати
              </Button>          
          </Modal.Footer>
         </Modal>
    </div>
  )
}

export default VideoWindow