import React from 'react';
import { Carousel, CarouselItem, Image } from 'react-bootstrap';
import c from './happy_style.module.css';
// import cover1 from 'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover1.jpg?alt=media&token=ce8552ff-cd63-45c5-8b13-859c3bf23283';
// import cover2 from '../assets/cover2.jpg';
// import cover3 from '../assets/cover3.jpg';
// import cover4 from '../assets/cover4.jpg';
// import cover5 from '../assets/cover5.jpg';
// import cover6 from '../assets/cover6.jpg';
// import cover7 from '../assets/cover7.jpg';
// import cover8 from '../assets/cover8.jpg';
// import cover9 from '../assets/cover9.jpeg';
// import cover10 from '../assets/cover10.jpg';
// import cover11 from '../assets/cover11.jpg';
// import cover12 from '../assets/cover12.jpg';
// import cover13 from '../assets/cover13.jpg';
// import cover14 from '../assets/cover14.jpg';
// import cover15 from '../assets/cover15.jpg';
// import cover16 from '../assets/cover16.jpg';
// import cover17 from '../assets/cover17.jpg';

// import cover1 from '../assets/cover1.jpg';
// import cover2 from '../assets/cover2.jpg';
// import cover3 from '../assets/cover3.jpg';
// import cover4 from '../assets/cover4.jpg';
// import cover5 from '../assets/cover5.jpg';
// import cover6 from '../assets/cover6.jpg';
// import cover7 from '../assets/cover7.jpg';
// import cover8 from '../assets/cover8.jpg';
// import cover9 from '../assets/cover9.jpeg';
// import cover10 from '../assets/cover10.jpg';
// import cover11 from '../assets/cover11.jpg';
// import cover12 from '../assets/cover12.jpg';
// import cover13 from '../assets/cover13.jpg';
// import cover14 from '../assets/cover14.jpg';
// import cover15 from '../assets/cover15.jpg';
// import cover16 from '../assets/cover16.jpg';
// import cover17 from '../assets/cover17.jpg';

const CaruselComponent = () => {

// const images = [
//         cover1, cover2, cover3, cover4, cover5, cover6, 
//         cover7, cover8, cover9, cover10, cover11,
//         cover12, cover13, cover14, cover15, cover16, cover17
//     ];

const images = [
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover1.jpg?alt=media&token=ce8552ff-cd63-45c5-8b13-859c3bf23283',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover2.jpg?alt=media&token=9ffb3ec0-c9dd-40e4-8e69-a1c8df2c3557',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover22.jpg?alt=media&token=5ab33d76-37c1-464b-a3c8-72783648229a',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover3.jpg?alt=media&token=c3c0955d-b482-4b9e-97b1-ca5087330754',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover4.jpg?alt=media&token=b899fd33-3c9e-4d89-8d0c-99ab39243fdb',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover5.jpg?alt=media&token=ef7d97ee-9dc3-4f58-9ab6-ab57f6df4b24',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover6.jpg?alt=media&token=1cdb3bc4-d476-4d06-a92f-82024204eaeb',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover7.jpg?alt=media&token=c16b06df-1d6e-43e9-9a99-628c9dc6f7f6',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover8.jpg?alt=media&token=77868548-9746-4b17-93fb-0a424303f183',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover9.jpeg?alt=media&token=21bd1075-a8bf-4e4e-aeff-111b19fbd67f',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover101.jpeg?alt=media&token=1b889703-3bed-4e4f-9552-ad96a75dbe60',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover10.jpg?alt=media&token=4920bf36-589d-4ca1-bb72-045af260b8d4',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover11.jpg?alt=media&token=aec100d7-58f7-48bf-87e2-3a5baf1d2e08',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover12.jpg?alt=media&token=caa74c0b-5bf6-4385-a0c4-e37bf0e66947',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover13.jpg?alt=media&token=2c546b40-68c8-4b88-8a2a-6f77947593ab',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover14.jpg?alt=media&token=52e7468a-aba0-4b34-9ce6-462404ddc18e',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover15.jpg?alt=media&token=4a599f2c-7f35-4907-be4b-40cab675abc2',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover16.jpg?alt=media&token=1609a9d5-cc29-48da-8c0d-c50114acee9e',
'https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover17.jpg?alt=media&token=fdd65736-7c7a-4cb2-b604-bbf4cde19ab9'
];

  return (
          <Carousel  className={c.carusel}>
            {images.map( (image, index) =>                     
              <CarouselItem key={index} >                                               
                  <Image key={index} src={image} id={c.cover2} className={``} />
              </CarouselItem>)            
            }
           </Carousel>   
  )
}

export default CaruselComponent