
import './App.css';
import { HashRouter as Router, Switch, Route} from 'react-router-dom';
//import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import HappyBirthday from './HappyBirthday/HappyBirthday';

import NoPage from "./Pages/NoPage";
import PlaySound from './SoundPlayers/PlaySound';

// import Signup from './Auth/Signup';
// import Login from './Auth/Login';
// import Logout from './Auth/Logout';
// import ForgotPassword from './Auth/ForgotPassword';
// import { AuthProvider } from './Context/AuthContext';
// import PrivateRoute from './Auth/PrivateRoute';
// import UpdateProfile from './Auth/UpdateProfile';




function App() {


  return (
   
 

     <div className="App">
      
      <Router>
       {/* <AuthProvider> */}
         <Switch>
          {/* <PrivateRoute exact path="/" component={DashboardData}/>          
          <PrivateRoute path="/financial" component={Financial} /> */}
            {/* <PrivateRoute path="/website" component={WebSite}/> */}

          <Route path="/" component={HappyBirthday}/>
         
          {/* <PrivateRoute path="/update_profile" component={UpdateProfile} /> 
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/forgot_password" component={ForgotPassword} /> */}

          <Route path="*" component={NoPage}/>
          
         </Switch>
        
       {/* </AuthProvider> */}
      </Router>
      <PlaySound/>
     </div>
    
  );
}

export default App;


        //    <Switch>
        //   <Route path='/' exect component={App} />
        //   <Route path='/Data' component={Data} />
        //   <Route path='/Agreement' component={Agreement} />
        //   <Route path='/Statement' component={Statement} />          
        //  </Switch>

        //  <Link to='Data'>Заполнить Данные</Link>
        //  <Link to='Agreement'>Перейти к Договору Партнерства</Link>
        //  <Link to='Statement'>Перейти к Акту Приема-Передачи</Link>