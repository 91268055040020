import React, { useState, useEffect } from "react";
import {  ArrowClockwiseIcon, PlayIcon, PauseIcon } from '../Components/Icons';
import c from './players_style.module.css';
//import sound from '../assets/sonyachna.mp3';

const useAudio = (url, setTrack) => {
  
  const [playing, setPlaying] = useState(true); 
  const [audio] = useState(new Audio(url));
  const toggle = () => setPlaying(!playing);

  useEffect(() => { playing ? audio.play() : audio.pause();},
    // eslint-disable-next-line 
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setTrack(7));
    return () => {
      audio.removeEventListener('ended', () => setTrack(7));
    };
    // eslint-disable-next-line 
  }, []);
  return [playing, toggle];
};


//VolumeMuteIcon(35, 'red') : VolumeUpIcon(35)
const Player6 = ( {setTrack} ) => { 
    const [playing, toggle] = useAudio('https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/sonyachna.mp3?alt=media&token=06cb7a4a-c0e7-4e5b-97ae-29fb147bcfd2', setTrack);

    const changeTrack = () => {
         toggle();
        setTrack(7);        
    };
    const backTrack = () => { 
        toggle();
       setTrack(5); 
    };

    return (
      <>
      
       <div className={c.screen}>
         <span className={c.run_the_text} >
          Сальто Назад - Сонячна 
         </span>      
       </div>
        <div className={c.volume_box3} onClick={() => backTrack()}>
          <span className={c.turnaround}>{ArrowClockwiseIcon(35)}</span>
        </div>

          <div onClick={toggle} className={c.volume_box}>
            {playing ? PauseIcon(35, 'red') : PlayIcon(35)}    
          </div>
          
          <div className={c.volume_box2} onClick={() => changeTrack()}>
            {ArrowClockwiseIcon(35)}
          </div>     
      
      </>
    );
  };

export default Player6;