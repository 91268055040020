import {useState, useEffect} from 'react';
import Player from './Player';
import Player2 from './Player2';
import Player3 from './Player3';
import Player4 from './Player4';
import Player5 from './Player5';
import Player6 from './Player6';
import Player7 from './Player7';


const PlaySound = () => {
  const [track, setTrack] = useState(1);
  const [playing, setPlaying] = useState(<Player setTrack={setTrack}/>);
useEffect(() => {
  if(track === 1) {
    setPlaying(<Player setTrack={setTrack}/>);
  } else if(track === 2) {
    setPlaying(<Player2 setTrack={setTrack}/>);
  } else if(track === 3) {
    setPlaying(<Player3 setTrack={setTrack}/>);
  } else if(track === 4) {
    setPlaying(<Player4 setTrack={setTrack}/>);
  } else if(track === 5) {
    setPlaying(<Player5 setTrack={setTrack}/>);
  } else if(track === 6) {
    setPlaying(<Player6 setTrack={setTrack}/>);
  } else if(track === 7) {
    setPlaying(<Player7 setTrack={setTrack}/>);
  }
},[track]);
  return (
    <div>
         {playing}
    </div>
  )
}

export default PlaySound